import React from 'react';
import styled from '@emotion/styled';

import { AlignedMarkdown } from '../components/atoms/aligned-markdown';
import { Section } from '../components/molecules/section';
import { useBreakpoints } from '../hooks/useBreakpoints';

export type LegalsTemplateProps = {
  markdown: string;
};

const Wrapper = styled.div`
  a,
  a:hover,
  a:visited {
    color: ${({ theme }) => theme.color.palette['base-blue']};
  }
  p {
    line-height: 26px;
  }

  h3::before {
    display: block;
    content: ' ';
    margin-top: -110px;
    height: 110px;
    visibility: hidden;
    pointer-events: none;
  }

  margin-bottom: 30px;
`;

export const LegalsTemplate: React.FC<LegalsTemplateProps> = ({ markdown }) => {
  const { isDesktop } = useBreakpoints();

  return (
    <>
      <Section marginPreset={isDesktop ? 'top-bottom' : 'none'} firstChildMarginPreset={isDesktop ? 'none' : 'all'}>
        <Wrapper>
          <AlignedMarkdown maxWidth="full_width" markdown={markdown} align="justify" addTableOfContent />
        </Wrapper>
      </Section>
    </>
  );
};
